import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 967.000000 963.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,963.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M3510 7236 l0 -1494 45 -55 c49 -61 131 -129 245 -204 75 -50 235
-143 245 -143 2 0 5 281 5 625 0 410 -3 625 -10 625 -6 0 -11 -49 -12 -122
l-2 -123 -6 125 -6 125 -5 -85 c-4 -66 -5 -49 -7 78 -1 100 -6 162 -12 162 -6
0 -10 33 -10 80 0 44 -3 80 -7 80 -5 0 -6 34 -5 76 2 49 0 79 -7 84 -7 4 -14
55 -18 123 -3 64 -10 117 -15 117 -4 0 -8 51 -8 113 0 61 -7 157 -15 212 -8
55 -15 130 -15 168 0 37 -4 67 -9 67 -5 0 -12 71 -16 158 -4 86 -11 159 -16
162 -10 7 -15 204 -5 240 3 14 3 19 0 13 -11 -25 -24 -13 -24 21 0 19 -5 38
-11 42 -8 4 -7 9 2 15 18 12 32 11 24 0 -3 -5 0 -13 6 -17 8 -4 9 -3 5 4 -9
15 -1 15 27 1 14 -6 11 -2 -7 13 -29 21 -343 208 -352 208 -2 0 -4 -672 -4
-1494z m277 1288 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m240
-2316 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z"
          />
          <path
            d="M3932 8474 c10 -10 20 -16 22 -13 3 3 -5 11 -17 18 -21 13 -21 12 -5
-5z"
          />
          <path
            d="M4241 6633 l1 -1408 259 -130 c143 -71 264 -131 269 -133 7 -3 9 211
8 615 -1 340 -5 580 -8 533 -5 -68 -7 -76 -10 -40 -3 25 -7 -119 -10 -320 l-5
-365 -5 445 c-2 245 -6 405 -9 355 -6 -85 -6 -81 -12 75 -4 107 -10 165 -18
168 -7 2 -11 33 -11 83 0 60 -3 79 -14 79 -10 0 -15 26 -20 103 -3 56 -10 154
-16 217 -6 63 -13 161 -16 218 -4 56 -10 102 -13 102 -4 0 -5 16 -3 36 3 20 0
39 -6 43 -5 3 -13 58 -16 121 -3 63 -10 116 -16 118 -6 2 -10 46 -10 107 0 58
-4 105 -10 107 -5 2 -11 12 -13 23 -2 11 -9 30 -16 43 -12 22 -11 23 9 12 12
-6 24 -9 27 -6 3 3 20 -2 37 -11 38 -19 23 -2 -20 24 -17 10 -60 36 -95 57
-91 55 -230 136 -235 136 -2 0 -4 -633 -3 -1407z"
          />
          <path
            d="M4550 7811 c0 -6 5 -13 12 -17 7 -4 8 -3 4 4 -9 15 -1 15 27 1 21
-10 21 -10 3 5 -22 18 -46 21 -46 7z"
          />
          <path d="M4630 7806 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
          <path
            d="M4940 7613 c0 -17 23 -33 251 -163 43 -25 99 -58 125 -73 26 -15 54
-25 61 -22 8 2 11 1 8 -5 -7 -11 77 -62 96 -57 8 2 10 2 6 -1 -27 -14 167
-111 298 -148 118 -34 168 -54 313 -120 40 -18 70 -29 66 -23 -3 6 -18 15 -32
21 -26 10 -26 11 -7 19 14 6 10 7 -15 3 -23 -3 -30 -2 -22 4 11 7 11 10 -3 15
-9 4 -13 3 -10 -3 10 -16 -7 -12 -39 9 -17 11 -34 17 -40 14 -5 -4 -7 0 -3 9
4 12 0 15 -16 11 -18 -4 -20 -3 -7 7 12 9 9 11 -15 6 -17 -2 -37 0 -45 5 -11
8 -9 9 9 4 16 -5 21 -4 17 4 -4 6 -14 11 -21 11 -20 0 -125 43 -125 51 0 4 14
12 30 19 27 11 33 11 46 -6 9 -11 12 -12 8 -4 -6 11 -2 10 13 -3 21 -19 33
-14 33 15 0 14 -8 18 -40 18 l-40 0 0 80 0 80 -40 0 -40 0 0 68 c0 63 -9 95
-24 92 -3 0 -14 4 -24 9 -16 10 -16 10 1 11 10 0 28 -5 39 -11 16 -8 19 -8 13
1 -5 9 -1 10 16 5 13 -4 28 -11 34 -16 5 -5 17 -9 27 -9 19 0 24 -15 8 -25 -5
-3 -10 -11 -10 -16 0 -5 9 -1 21 10 12 11 28 17 36 14 11 -4 10 -9 -7 -25 -12
-11 -19 -23 -16 -26 4 -3 12 3 20 13 8 11 18 15 26 10 9 -6 11 -4 5 5 -9 15
28 5 65 -18 l25 -15 -19 21 c-11 12 -14 22 -9 22 6 0 16 -7 23 -15 7 -8 22
-15 34 -15 16 1 12 5 -16 19 -21 11 -48 17 -60 14 -19 -4 -20 -3 -8 6 14 10 7
12 -35 11 -3 0 -19 7 -36 16 -17 8 -33 13 -36 10 -3 -3 -14 -1 -23 4 -10 5
-33 11 -51 14 -19 2 -52 9 -74 14 -98 26 -299 41 -542 42 -206 0 -223 -1 -223
-17z m620 -23 c-8 -5 -22 -9 -30 -9 -10 0 -8 3 5 9 27 12 43 12 25 0z m-133
-6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m61 -1 c-10 -2 -26
-2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m201 -9 c2 -2 -7 -4 -20 -5 l-24
-1 25 -8 c18 -5 11 -7 -27 -6 -28 1 -49 4 -46 9 2 4 -4 7 -14 8 -16 0 -16 1 2
9 18 7 94 3 104 -6z m-138 -17 c-4 -4 -23 -4 -42 1 l-34 10 42 -1 c24 -1 39
-5 34 -10z m-456 -25 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z
m340 -152 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m90 -91
c-8 -12 1 -11 28 2 21 10 21 10 2 -6 -23 -18 -37 -15 -41 8 -1 9 2 15 7 11 5
-3 7 -10 4 -15z m65 -19 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4
10 6 0 11 -4 11 -10z m80 -60 c8 -5 12 -11 9 -14 -3 -3 -14 1 -25 9 -21 16 -8
20 16 5z m57 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m130
-60 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
          />
          <path d="M5608 7573 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path d="M6071 7476 c2 -2 18 -6 34 -10 22 -5 26 -4 15 4 -14 9 -59 15 -49 6z" />
          <path
            d="M6169 7430 c24 -11 46 -18 48 -16 7 7 -54 36 -74 36 -10 0 2 -9 26
-20z"
          />
          <path
            d="M6241 7406 c2 -2 15 -9 29 -15 24 -11 24 -11 6 3 -16 13 -49 24 -35
12z"
          />
          <path
            d="M4962 6117 c-3 -1209 -2 -1262 15 -1273 10 -6 45 -26 78 -44 82 -45
301 -189 380 -250 l65 -50 -2 767 -3 768 -5 -525 c-3 -289 -6 -480 -8 -424 -6
263 -17 467 -23 419 -3 -28 -7 23 -7 118 -2 135 -5 167 -16 167 -11 0 -15 28
-21 120 -4 66 -11 120 -15 120 -4 0 -10 46 -14 103 -9 136 -9 137 -18 137 -4
0 -8 54 -8 120 0 73 -4 120 -10 120 -5 0 -10 21 -11 48 0 26 -5 72 -9 102 -5
30 -14 113 -20 185 -6 71 -15 134 -20 139 -15 15 -13 123 2 128 9 3 8 10 -5
23 -17 16 -17 19 -3 27 9 5 23 5 35 -2 11 -5 23 -10 27 -10 8 0 -80 55 -156
97 -25 14 -52 31 -60 38 -13 10 -159 95 -163 95 -1 0 -3 -568 -5 -1263z m313
1063 c-3 -5 -14 -10 -23 -9 -14 0 -13 2 3 9 27 11 27 11 20 0z m-28 -26 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
          />
          <path d="M6317 7363 c-3 -5 6 -9 20 -10 19 -1 22 1 12 8 -17 11 -26 11 -32 2z" />
          <path d="M6407 7319 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
          <path
            d="M6440 7303 c0 -8 61 -46 66 -41 1 2 -13 13 -32 26 -19 12 -34 19 -34
15z"
          />
          <path d="M6061 7124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M5380 7126 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z" />
          <path
            d="M6790 7046 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
          />
          <path
            d="M6190 6981 c34 -26 48 -31 53 -18 3 7 -3 13 -12 13 -9 1 -25 4 -36 8
-16 7 -17 6 -5 -3z"
          />
          <path
            d="M6252 6949 c2 -5 33 -28 68 -51 l65 -40 -35 34 c-19 19 -39 33 -43
30 -4 -2 -16 6 -26 17 -20 22 -35 28 -29 10z"
          />
          <path d="M4043 6790 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z" />
          <path
            d="M6457 6798 c21 -17 56 -48 78 -67 21 -20 33 -27 25 -16 -18 23 -58
62 -99 93 -44 33 -47 27 -4 -10z"
          />
          <path
            d="M6610 6655 c33 -35 40 -39 40 -30 0 7 -59 65 -67 65 -4 0 8 -16 27
-35z"
          />
          <path d="M7166 6607 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
          <path
            d="M6670 6596 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"
          />
          <path
            d="M6718 6542 c12 -11 22 -23 22 -26 0 -3 14 -25 30 -48 17 -22 30 -37
30 -31 0 18 -55 93 -80 109 l-25 16 23 -20z"
          />
          <path d="M6856 6347 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z" />
          <path
            d="M6897 6282 c3 -12 9 -25 14 -28 14 -8 11 10 -6 32 -13 18 -14 18 -8
-4z"
          />
          <path d="M4753 6195 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z" />
          <path d="M6950 6171 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z" />
          <path
            d="M6991 6093 c5 -16 12 -34 15 -42 2 -8 7 -11 10 -9 6 6 -22 78 -30 78
-3 0 -1 -12 5 -27z"
          />
          <path d="M7021 6025 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z" />
          <path d="M7071 5864 c6 -21 13 -41 16 -44 9 -9 -9 65 -18 74 -4 5 -3 -8 2 -30z" />
          <path d="M7091 5784 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M7101 5744 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M7113 5675 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z" />
          <path d="M7122 5595 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
          <path d="M7532 5575 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
          <path d="M7152 5530 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
          <path
            d="M7132 5480 c0 -19 4 -48 8 -65 7 -29 8 -28 8 10 0 22 -4 51 -8 65 -7
23 -8 22 -8 -10z"
          />
          <path d="M7141 5314 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path
            d="M7136 5268 c-4 -15 -5 -31 -2 -36 3 -5 1 -24 -4 -43 -4 -19 -11 -65
-14 -103 -13 -147 -83 -362 -183 -561 -39 -78 -147 -247 -190 -296 -96 -111
-132 -150 -182 -200 -60 -60 -199 -169 -286 -224 -56 -36 -224 -121 -285 -145
-42 -17 -50 -26 -50 -63 0 -65 -46 -204 -105 -323 -34 -68 -61 -124 -60 -124
16 0 259 76 325 101 185 71 456 227 593 342 168 140 312 287 387 393 19 27 57
81 84 119 66 95 103 163 199 365 12 25 22 47 23 50 0 3 6 18 13 34 9 23 9 31
0 37 -7 4 -16 24 -20 45 -8 37 -8 36 -4 -9 3 -26 1 -46 -3 -43 -5 3 -7 -15 -6
-40 2 -30 -1 -42 -8 -38 -7 5 -9 0 -6 -14 4 -15 0 -23 -14 -28 -13 -4 -18 -12
-15 -24 3 -13 1 -17 -10 -13 -12 5 -14 1 -9 -14 8 -23 -2 -53 -16 -53 -6 0 -5
6 2 15 8 9 9 20 3 28 -5 6 -10 24 -11 38 -2 20 1 27 14 25 19 -1 42 29 30 41
-4 4 -12 0 -17 -10 -12 -20 -27 -23 -26 -4 0 6 -1 49 -1 95 l-2 82 -60 0 c-47
0 -60 3 -60 15 0 8 9 15 20 15 18 0 20 7 20 70 l0 71 -27 -26 c-16 -14 -33
-23 -40 -21 -6 3 -14 -3 -16 -12 -3 -10 -6 -4 -7 13 l-2 30 -8 -30 -7 -30 -11
28 c-9 20 -9 33 -2 45 9 14 10 12 5 -8 -5 -25 -5 -25 6 -3 11 21 13 22 26 5
12 -16 13 -16 8 1 -15 51 -16 52 -26 37 -8 -11 -9 -7 -5 15 3 17 12 68 20 115
9 47 18 105 21 130 3 25 8 64 11 86 8 55 0 91 -10 52z m-72 -493 c-4 -8 -8
-15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m-33 -92 c-12
-20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m6 -60 l0 -38 -12 29
c-7 17 -15 24 -18 17 -4 -8 -5 -7 -2 1 1 7 8 12 14 11 6 -2 11 2 11 7 0 6 1
10 3 10 2 0 3 -17 4 -37z m349 -79 c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9
9 15 11 12 3 -3 3 -13 -1 -22z m-433 -94 c3 -11 1 -18 -4 -14 -5 3 -9 12 -9
20 0 20 7 17 13 -6z m271 -115 c-4 -8 -10 -15 -15 -15 -4 0 -6 7 -3 15 4 8 10
15 15 15 4 0 6 -7 3 -15z m32 -30 c-21 -16 -32 -13 -21 4 3 6 14 11 23 11 15
-1 15 -2 -2 -15z m-16 -59 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m-70 -110 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6
5 11 10 11 6 0 10 -2 10 -4z"
          />
          <path
            d="M7171 5263 c0 -12 -4 -30 -7 -40 -5 -17 -5 -17 6 0 6 10 9 28 7 40
-4 22 -4 22 -6 0z"
          />
          <path d="M7182 5170 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
          <path
            d="M7150 5165 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"
          />
          <path d="M7522 5080 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
          <path
            d="M7140 4971 c0 -28 11 -52 21 -45 16 9 11 64 -6 64 -8 0 -15 -9 -15
-19z"
          />
          <path
            d="M7496 4929 c-8 -19 -20 -67 -27 -108 -6 -40 -14 -69 -18 -63 -3 6 -7
-8 -7 -31 -1 -37 0 -39 8 -17 14 37 40 152 40 177 0 14 4 20 11 16 6 -3 7 -1
3 6 -4 6 -2 19 5 27 6 8 9 17 6 20 -3 4 -13 -9 -21 -27z"
          />
          <path
            d="M7107 4913 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"
          />
          <path d="M7452 4830 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
          <path d="M7421 4644 c-7 -20 -11 -38 -8 -40 3 -3 10 11 17 31 16 47 8 55 -9 9z" />
          <path d="M7395 4649 c-10 -30 0 -33 13 -4 6 14 7 25 2 25 -5 0 -12 -9 -15 -21z" />
          <path
            d="M3611 3793 c-19 -10 -48 -35 -65 -56 l-31 -39 -3 -229 c-3 -218 -2
-231 18 -272 13 -26 38 -54 63 -70 l41 -27 634 0 634 0 -6 38 c-16 88 -126
264 -223 354 l-29 28 -353 2 -353 3 -3 76 c-3 90 -23 139 -73 177 -31 24 -45
27 -126 30 -72 2 -98 -1 -125 -15z"
          />
          <path
            d="M2312 1932 c-9 -7 -12 -62 -10 -243 l3 -234 23 -3 c48 -7 52 8 52
179 0 88 3 159 8 159 4 -1 25 -26 47 -56 59 -81 86 -114 94 -114 5 0 36 38 71
85 35 47 67 85 71 85 5 0 9 -74 9 -165 0 -179 2 -184 55 -171 l25 6 -1 235 0
235 -24 6 c-44 11 -59 -1 -134 -100 -61 -83 -76 -97 -85 -84 -125 175 -137
188 -164 188 -15 0 -33 -4 -40 -8z m428 -46 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2
-9 4 6 10 25 14 25 6z m-3 -53 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21
0 -30z m0 -255 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z"
          />
          <path
            d="M3006 1918 c-29 -48 -178 -437 -173 -452 8 -19 50 -21 65 -3 6 7 19
34 29 61 l18 48 104 0 104 0 20 -54 c25 -67 48 -83 86 -59 12 8 12 15 -2 53
-9 23 -46 124 -82 223 -37 99 -72 186 -77 193 -18 21 -76 15 -92 -10z m83
-164 c17 -47 31 -89 31 -95 0 -5 -33 -9 -74 -9 l-74 0 30 73 c16 39 34 82 39
95 5 12 10 22 13 22 2 0 18 -38 35 -86z m49 -39 c-3 -3 -9 2 -12 12 -6 14 -5
15 5 6 7 -7 10 -15 7 -18z"
          />
          <path
            d="M3452 1931 c-8 -4 -12 -21 -10 -37 3 -26 7 -29 41 -32 l37 -3 0 -148
c0 -171 -5 -184 -65 -179 -36 3 -41 7 -51 38 -11 32 -15 35 -50 35 -38 0 -39
-1 -37 -33 3 -79 81 -132 172 -117 102 17 123 69 119 305 l-3 175 -70 3 c-38
1 -76 -2 -83 -7z m-102 -396 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10
-5 5 -8 10 -19 10 -25z m123 -42 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19
-2 13 -5z"
          />
          <path
            d="M3733 1930 c-23 -9 -23 -10 -23 -233 0 -234 2 -247 45 -247 43 0 45
13 45 251 0 198 -2 228 -16 233 -19 7 -23 7 -51 -4z m34 -16 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
          />
          <path
            d="M3913 1934 c-10 -4 -13 -60 -13 -229 0 -123 3 -230 6 -239 8 -20 126
-22 204 -4 100 23 170 117 170 228 0 101 -68 204 -152 229 -46 14 -195 24
-215 15z m160 -41 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m78
-69 c61 -62 58 -195 -7 -259 -29 -29 -40 -34 -94 -37 -34 -1 -64 -1 -66 1 -6
6 -5 327 1 332 2 3 34 2 71 -2 55 -5 71 -12 95 -35z m-191 -324 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m103 3 c-13 -2 -33
-2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z"
          />
          <path
            d="M4518 1923 c-15 -19 -173 -412 -180 -448 -2 -14 3 -21 19 -23 36 -5
57 13 76 66 l17 51 104 3 104 3 17 -50 c10 -27 22 -56 28 -62 11 -14 63 -18
71 -5 9 15 -151 453 -172 470 -23 17 -66 15 -84 -5z m76 -160 c15 -43 31 -85
36 -95 12 -22 0 -25 -81 -20 l-67 4 29 75 c29 75 47 113 53 113 2 0 15 -35 30
-77z m-151 -160 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"
          />
          <path
            d="M4862 1932 c-9 -6 -12 -62 -10 -243 l3 -234 102 1 c86 1 109 5 150
25 62 31 100 86 114 166 20 116 -30 220 -129 265 -47 21 -208 36 -230 20z
m222 -95 c39 -26 58 -78 55 -151 -4 -105 -49 -151 -154 -157 l-50 -3 -3 159
c-1 88 -1 164 2 170 6 17 118 3 150 -18z m109 -132 c-3 -9 -8 -14 -10 -11 -3
3 -2 9 2 15 9 16 15 13 8 -4z m-16 -67 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-53 -116 c2 -4 -4 -8 -14 -8 -10 0 -16 4 -14 8 3 4 9 8 14 8
5 0 11 -4 14 -8z"
          />
          <path
            d="M5327 1933 c-4 -3 -7 -111 -7 -240 0 -253 0 -253 55 -239 l25 6 0
234 c0 204 -2 235 -16 240 -21 8 -49 8 -57 -1z m46 -20 c-7 -2 -19 -2 -25 0
-7 3 -2 5 12 5 14 0 19 -2 13 -5z"
          />
          <path
            d="M5728 1934 c-5 -4 -8 -112 -8 -240 l0 -234 26 -6 c33 -8 184 3 219
16 14 5 45 30 69 54 54 56 71 111 61 200 -14 137 -99 205 -268 214 -51 2 -95
1 -99 -4z m239 -105 c34 -32 45 -63 45 -129 0 -115 -50 -170 -154 -170 l-58 0
0 163 c0 90 2 166 5 168 2 3 34 2 70 -2 49 -5 73 -13 92 -30z m80 -61 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m20 -50 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z"
          />
          <path
            d="M6338 1923 c-8 -10 -47 -101 -87 -203 -40 -102 -80 -200 -88 -219
-18 -39 -8 -54 33 -49 22 2 33 12 47 42 10 21 19 47 20 57 1 17 11 19 107 19
l107 0 26 -60 c23 -54 28 -60 56 -60 51 0 56 -21 -67 312 -28 76 -56 147 -62
158 -14 26 -71 27 -92 3z m77 -54 c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14 5 0
13 -5 16 -11z m2 -121 c18 -50 32 -92 30 -94 -6 -6 -138 -7 -143 -1 -4 4 -2
12 3 19 5 7 23 48 39 91 16 42 31 77 34 77 3 0 20 -41 37 -92z"
          />
          <path
            d="M6633 1933 c-7 -2 -13 -18 -13 -34 0 -34 25 -44 100 -37 27 3 55 1
62 -3 10 -7 10 -9 1 -9 -10 0 -13 -46 -13 -195 l0 -195 25 -6 c54 -13 54 -11
57 207 l3 202 70 1 70 1 0 35 0 35 -175 2 c-96 1 -181 -1 -187 -4z"
          />
          <path
            d="M7218 1923 c-8 -10 -47 -101 -87 -203 -40 -102 -80 -200 -88 -219
-18 -39 -8 -54 33 -49 22 2 33 12 47 42 10 21 19 47 20 57 1 17 11 19 107 19
l107 0 26 -60 c23 -54 28 -60 56 -60 51 0 55 -19 -72 324 -30 82 -61 152 -69
157 -22 15 -64 10 -80 -8z m69 -49 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m10 -46 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-2 -74 c17 -48 30 -91 30 -96 0 -11 -132 -15 -142 -4 -3 3 -1 11 4 18 5 7 23
48 39 91 16 42 31 77 34 77 3 0 19 -39 35 -86z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
